<template>
	<div>
		<div>
			<p class="heading mb-3">Menu Heading</p>
			<v-text-field
				class="pa-0"
				v-model="label"
				outlined
				placeholder="Enter a heading for the menu of links"
				dense
			></v-text-field>
			<div class="d-flex justify-space-between align-end mb-5">
				<h1 v-if="links.length > 0" class="heading">Links</h1>
				<v-spacer></v-spacer>
				<v-btn
					outlined
					color="var(--blue)"
					right
					:disabled="label === ''"
					@click="addLink()"
				>
					+ Add Link
				</v-btn>
			</div>
			<draggable v-model="links" class="drag-area" ghost-class="ghost">
				<transition-group name="sortable">
					<div
						v-for="link in links"
						:key="link.uuid"
						class="mb-1 links"
					>
						<div
							class="
								d-flex
								justify-space-between
								align-start
								pt-2
							"
						>
							<div class="d-flex flex-row">
								<p class="mr-3">
									{{ link.label }}
								</p>

								<p class="path">
									{{ link.path }}
								</p>
							</div>
							<div class="d-flex flex-row">
								<span
									class="mdi mdi-pencil edit mr-2"
									@click="editLink(link)"
								></span>
								<span
									class="mdi mdi-close-circle remove"
									@click="removeLink(link.uuid)"
								></span>
							</div>
						</div>
					</div>
				</transition-group>
			</draggable>
			<v-navigation-drawer
				v-model="drawer"
				fixed
				stateless
				temporary
				right
				class="link-drawer"
				v-if="showAddLinkDrawer"
			>
				<div class="d-flex align-center header pt-4 px-2 pb-2">
					<span
						class="mdi mdi-chevron-left back-icon"
						@click="closeDrawer()"
					></span>
					<h1 class="crud-title text-center">Page</h1>
				</div>
				<AddLink
					:initial-data="link"
					@savedLink="updateLinks($event)"
					class="px-4"
				></AddLink>
			</v-navigation-drawer>
		</div>
		<v-footer class="footer">
			<v-btn @click="saveLink()" depressed class="save"
				>Done</v-btn
			></v-footer
		>
	</div>
</template>
<script>
import AddLink from './AddLink'
import draggable from 'vuedraggable'
import { v4 as uuidv4 } from 'uuid'

export default {
	props: {
		initialData: {
			type: Object,
		},
	},
	components: {
		AddLink,
		draggable,
	},
	data() {
		return {
			drawer: true,
			showAddLink: false,
			label: '',
			links: [],
			link: {},
			uuid: '',
			showAddLinkDrawer: false,
		}
	},
	created() {
		if (this.initialData.label) {
			this.links = this.initialData.links.map((link) => ({
				label: link.label,
				path: link.path,
				linkType: link.linkType,
				newWindow: link.newWindow,
				uuid: uuidv4(),
			}))
			this.label = this.initialData.label
		}
		this.uuid = this.initialData.uuid
	},
	methods: {
		closeDrawer() {
			this.showAddLinkDrawer = !this.showAddLinkDrawer
			if (this.link.label === '') {
				this.removeLink(this.link.uuid)
			}
		},
		addLink() {
			this.showAddLinkDrawer = !this.showAddLinkDrawer
			this.link = {
				label: '',
				path: '',
				newWindow: 'current',
				uuid: uuidv4(),
			}
			let arr = this.links
			arr.push(this.link)
			this.links = arr
		},
		updateLinks(emittedObj) {
			let links = this.links
			links.every(function (link, index) {
				if (link.uuid === emittedObj.uuid) {
					let obj = {
						label: emittedObj.label,
						path: emittedObj.path,
						newWindow: emittedObj.newWindow,
						linkType: emittedObj.linkType,
						uuid: emittedObj.uuid,
					}
					links[index] = obj
					return false // to break the loop
				} else {
					return true
				}
			})
			this.links = links
			this.showAddLinkDrawer = false
		},
		removeLink(linkId) {
			let arr = this.links
			arr.forEach((link, index) => {
				if (link.uuid === linkId) {
					arr.splice(index, 1)
					return false // to break the loop
				} else {
					return true
				}
			})
			this.links = arr
		},
		editLink(link) {
			this.showAddLinkDrawer = !this.showAddLinkDrawer
			this.link = link
		},
		saveLink() {
			let menuObj = {
				label: this.label,
				uuid: this.uuid,
				links: this.links,
			}
			this.$emit('on-menu-link-update', menuObj)
		},
	},
}
</script>
<style scoped>
.path {
	color: grey;
}
.heading {
	font-size: 1.2rem;
	font-weight: 400;
}
.links {
	border: 1px solid #eee;
	padding: 0px 12px 10px 12px;
	padding-bottom: 10px;
}
.links:hover,
.section:hover {
	cursor: all-scroll;
}
.remove {
	font-size: 1.5rem;
	color: #f44336;
	cursor: pointer;
}
.edit {
	font-size: 1.3rem;
	color: var(--blue);
	cursor: pointer;
}
.link-drawer {
	width: 30% !important;
	height: 100% !important;
}
.link-drawer .header {
	border-bottom: 1px solid #eee;
}
.link-drawer .back-icon {
	font-size: 25px;
	cursor: pointer;
}
.link-drawer .crud-title {
	width: 100%;
}
.link-drawer .close-icon {
	font-size: 22px;
	cursor: pointer;
}
.errors {
	color: red;
	font-size: 13px;
}
.sortable-move {
	transition: transform 1s;
}
.link-drawer {
	width: 70% !important;
}
.link-drawer .header {
	border-bottom: 1px solid #eee;
}
.link-drawer .back-icon {
	font-size: 25px;
	cursor: pointer;
}
.link-drawer .crud-title {
	width: 100%;
}
.link-drawer .close-icon {
	font-size: 22px;
	cursor: pointer;
}
.footer {
	background-color: var(--blue);
	height: 60px;
}
.save {
	background-color: var(--blue) !important;
	color: white;
	width: 100% !important;
	height: 100% !important;
}
</style>
