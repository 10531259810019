<template>
	<div class="filters">
		<div class="py-2">
			<div class="pb-1 mb-4 header">
				<div
					class="
						px-8
						d-flex
						flex-row
						align-start
						justify-space-between
					"
				>
					<h1 class="crud-title">Customize Your Website's Navbar</h1>
				</div>
			</div>
			<div class="px-8">
				<div class="d-flex justify-space-between align-end mb-5">
					<p v-if="links.length > 0">Links</p>
					<v-spacer></v-spacer>
					<v-btn
						outlined
						color="var(--blue)"
						right
						@click="addLink()"
					>
						+ Add Link
					</v-btn>
				</div>
				<draggable
					v-model="links"
					class="drag-area"
					ghost-class="ghost"
				>
					<transition-group name="sortable">
						<v-card
							v-for="link in links"
							:key="link.uuid"
							class="mb-1 links pa-3"
						>
							<div
								class="
									d-flex
									justify-space-between
									align-center
								"
							>
								<p class="heading">
									{{ link.label }}
								</p>
								<div>
									<span
										class="mdi mdi-pencil edit mr-2"
										@click="editLink(link)"
									></span>
									<span
										class="mdi mdi-close-circle remove"
										@click="removeLink(link.uuid)"
									></span>
								</div>
							</div>
							<div
								v-if="link.links && link.links.length > 0"
								class="px-3"
							>
								<div
									v-for="(link, index) in link.links"
									:key="index"
									class="mb-1 links"
								>
									<div class="d-flex">
										<p class="mr-3">
											{{ link.label }}
										</p>
										<p class="path">
											{{ link.path }}
										</p>
									</div>
								</div>
							</div>
							<p class="path" v-else>
								{{ link.path }}
							</p>
						</v-card>
					</transition-group>
				</draggable>
			</div>
			<NavbarAddLinks
				:initial-data="link"
				v-if="showAddLinkDrawer"
				@new-navbar-link="updateLinks($event)"
				@close-navbar-drawer="closeDrawer"
			></NavbarAddLinks>
			<div class="d-flex flex-row px-8 pt-5" v-if="links.length > 0">
				<v-btn
					@click="saveNavbar()"
					depressed
					class="save"
					:loading="loading"
					>save</v-btn
				>
			</div>
		</div>
	</div>
</template>
<script>
import NavbarAddLinks from './components/NavbarAddLinks'
import draggable from 'vuedraggable'
import { v4 as uuidv4 } from 'uuid'

export default {
	components: {
		NavbarAddLinks,
		draggable,
	},
	data() {
		return {
			drawer: true,
			showAddLinkDrawer: false,
			links: [],
			link: {},
			loading: false,
		}
	},
	created() {
		this.getNavbar()
	},
	methods: {
		addLink() {
			this.showAddLinkDrawer = !this.showAddLinkDrawer
			this.link = {
				label: '',
				path: '',
				newWindow: false,
				uuid: uuidv4(),
			}
			let arr = this.links
			arr.push(this.link)
			this.links = arr
		},
		updateLinks(emittedObj) {
			let links = this.links
			links.every(function (link, index) {
				if (link.uuid === emittedObj.uuid) {
					links[index] = emittedObj
					return false // to break the loop
				} else {
					return true
				}
			})
			this.links = links
			this.showAddLinkDrawer = !this.showAddLinkDrawer
		},
		removeLink(linkId) {
			let arr = this.links

			arr.forEach((link, index) => {
				if (link.uuid === linkId) {
					arr.splice(index, 1)
					return false // to break the loop
				} else {
					return true
				}
			})
			this.links = arr
		},
		editLink(link) {
			this.showAddLinkDrawer = !this.showAddLinkDrawer
			this.link = link
		},
		closeDrawer() {
			this.showAddLinkDrawer = !this.showAddLinkDrawer
			if (this.link.label === '') {
				this.removeLink(this.link.uuid)
			}
		},
		saveNavbar() {
			this.loading = true
			const projectId = this.$store.state.app.projectId
			let payload = {
				links: this.links.map((link) => {
					if (link.links) {
						link.links.map((link) => {
							delete link.uuid
						})
						delete link.uuid
					} else {
						delete link.uuid
					}
					return link
				}),
			}
			this.axios({
				method: 'put',
				url: `/project/${projectId}/navbar`,
				data: payload,
			}).then((res) => {
				this.loading = false
				let navbar = res.data.data.navbar
				this.links = navbar.links.map((link) => ({
					label: link.label,
					links: link.links,
					newWindow: link.newWindow,
					path: link.path,
					uuid: uuidv4(),
				}))
			})
		},
		getNavbar() {
			const projectId = this.$store.state.app.projectId
			this.axios({
				method: 'get',
				url: `/project/${projectId}/navbar`,
			}).then((res) => {
				let navbar = res.data
				this.links = navbar.links.map((link) => ({
					label: link.label,
					links: link.links,
					newWindow: link.newWindow,
					path: link.path,
					linkType: link.linkType,
					uuid: uuidv4(),
				}))
			})
		},
	},
}
</script>
<style scoped>
.navbar-drawer {
	width: 50% !important;
}
.section-heading {
	font-size: 1.2rem;
}
.close-icon {
	font-size: 1.7rem;
	color: #b9b9b9;
}
.close-icon:hover {
	color: black;
	cursor: pointer;
}
.header {
	border-bottom: 1px solid #eee;
}
.remove {
	font-size: 1.5rem;
	color: #f44336;
	cursor: pointer;
}
.edit {
	font-size: 1.3rem;
	color: var(--blue);
	cursor: pointer;
}
.add {
	background-color: var(--blue) !important;
	color: white;
}
.path {
	color: grey;
}
.links:not(:last-child) {
	border-bottom: 1px solid #eee;
	padding-bottom: 10px;
}
.heading {
	font-size: 1rem;
}
.sortable-move {
	transition: transform 1s;
}
.links:hover {
	cursor: all-scroll;
}
.save {
	background-color: var(--blue) !important;
	color: white;
}
</style>
