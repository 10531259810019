<template>
	<div class="filters">
		<v-navigation-drawer
			v-model="drawer"
			temporary
			stateless
			fixed
			right
			class="footer-drawer"
		>
			<div class="py-4">
				<div class="pb-1 mb-4 header">
					<div
						class="px-8 d-flex flex-row align-start justify-space-between"
					>
						<h1 class="crud-title">Add Link to Navbar</h1>
						<span
							class="mdi mdi-close close-icon"
							@click="
								;(drawer = !drawer),
									$emit('close-navbar-drawer')
							"
						></span>
					</div>
				</div>
				<div class="px-8">
					<v-radio-group v-model="linkType" row>
						<v-radio label="Single Link" value="single"></v-radio>
						<v-radio label="Menu of Links" value="menu"></v-radio>
					</v-radio-group>
					<SingleLink
						v-if="linkType === 'single'"
						:initial-data="link"
						@on-single-link-update="updateLinks($event)"
					></SingleLink>
					<MenuLink
						v-else
						:initial-data="link"
						@on-menu-link-update="updateLinks($event)"
					></MenuLink>
				</div>
			</div>
		</v-navigation-drawer>
	</div>
</template>
<script>
import SingleLink from './SingleLink'
import MenuLink from './MenuLink'

export default {
	components: {
		MenuLink,
		SingleLink,
	},
	props: {
		initialData: {
			type: Object,
			required: true,
		},
	},
	created() {
		if (Object.keys(this.initialData).length > 0) {
			if (this.initialData.links && this.initialData.links.length > 0) {
				this.linkType = 'menu'
			} else {
				this.linkType = 'single'
			}
			this.link = this.initialData
		}
	},
	data() {
		return {
			drawer: true,
			linkType: 'single',
			link: {},
		}
	},
	methods: {
		updateLinks(emittedObj) {
			this.$emit('new-navbar-link', emittedObj)
		},
	},
}
</script>
<style scoped>
.footer-drawer {
	width: 50% !important;
}
.section-heading {
	font-size: 1.2rem;
}
.close-icon {
	font-size: 1.7rem;
	color: #b9b9b9;
}
.close-icon:hover {
	color: black;
	cursor: pointer;
}
.header {
	border-bottom: 1px solid #eee;
}
</style>
