<template>
	<AddLink :initial-data="link" @savedLink="updateLinks($event)"></AddLink>
</template>
<script>
import AddLink from './AddLink'
export default {
	props: {
		initialData: {
			type: Object,
			required: true,
		},
	},
	components: {
		AddLink,
	},
	created() {
		this.link = this.initialData
	},
	data() {
		return {
			link: {},
		}
	},
	methods: {
		updateLinks(emittedObj) {
			this.link = emittedObj
			this.$emit('on-single-link-update', this.link)
		},
	},
}
</script>
